import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import Stats from './components/stats';

export default function Reports() {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<Typography variant='h3' sx={{ mb: 1 }}>
				{t('navigation.reports')}
			</Typography>

			<Breadcrumbs aria-label='breadcrumb'>
				<Link component={RouterLink} color='inherit' to='/staff'>
					{t('navigation.dashboard')}
				</Link>
				<Typography color='text.primary'>
					{t('navigation.reports')}
				</Typography>
			</Breadcrumbs>

			<Divider sx={{ my: 3 }} />

			<Grid container spacing={3}>
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00006091' name='Signal Strength V4' />
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00006092' name='Temperature V4' />
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00006093' name='Humidity V4' />
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00006094' name='Voltage V4' />
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00006095' name='Signal Strength V3' />
				</Grid>
				{/* <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00006096' name='Temperature V3' />
				</Grid> */}
				{/* <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00006097' name='Humidity V3' />
				</Grid> */}
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00006098' name='Voltage V3' />
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00006099' name='Weight' />
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00006100' name='Bee Score' />
				</Grid>
				<Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
					<Stats id='00006101' name='Trend Score' />
				</Grid>
			</Grid>
		</React.Fragment>
	)
}